
import SpeedkitPicture from '#speedkit/components/SpeedkitPicture'
import SpeedkitImage from '#speedkit/components/SpeedkitImage'
import speedkitHydrate from '#speedkit/hydrate'

export default {
  name: 'HomePage',
  scrollToTop: true,
  head: {
    htmlAttrs: {
      lang: 'en',
    },
    title: 'Online video and audio editor (Free and Simple)',
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: 'Amplify your podcast, by converting into waveform video to share on on Facebook, Twitter and Instagram',
      },
    ],
  },
  components: {
    FooterSection: speedkitHydrate(() => import('@/components/FooterSection.vue')),
    LogoCloud: speedkitHydrate(() => import('@/components/global/LogoCloud.vue')),
    HeaderBar: speedkitHydrate(() => import('@/components/HeaderBar.vue')),
    SpeedkitPicture,
    SpeedkitImage,
  },
  data() {
    return {
      user: null,
      showMobileMenu: false,
    }
  },
  created() {
    this.user = this.$fire.auth.currentUser
  },
  computed: {
    corporationSchema() {
      return {
        '@context': 'https://schema.org',
        '@type': 'Corporation',
        name: 'EchoWave',
        url: 'https://echowave.io/',
        logo: 'https://echowave.io/images/logo/logo-mark.png',
        sameAs: ['https://www.facebook.com/echowaveio/', 'https://twitter.com/echowaveio', 'https://www.instagram.com/echowaveio/', 'https://www.youtube.com/channel/UCvE83TZ1WJkbTgC2leAKJIw', 'https://echowave.io/'],
      }
    },
  },
}
